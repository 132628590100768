import Error503 from "../503";
import { LoadBalancerMainStyled } from "./styled";

const LoadBalancer = () => {
  return (
    <Error503 />
    // <LoadBalancerMainStyled></LoadBalancerMainStyled>
  );
};
export default LoadBalancer;
